import * as React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductSummaryCardViewProps } from 'lib/themes/themes';

import config from 'config';
import baseStyles from '../base.module.css';
import styles from './ProductSummaryCardView.module.css';
import { getWeekdayText } from 'lib/util/getWeekdayText';
import { getDiscountText } from 'lib/util/getDiscountText';
import { useCurrencyFormatter, getDisplayPrice, getHasPromotion } from 'hooks/useCurrencyFormatter';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

const ProductSummaryCardView = ({
  product,
  productUrl,
  orientation,
}: ProductSummaryCardViewProps) => {
  const { t } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const hasPromotion = getHasPromotion(product);
  const discountText = getDiscountText(product, t, currencyFormatter);

  if (orientation === 'VERTICAL') {
    return (
      <Link href={productUrl}>
        <div className={styles['c-card']}>
          <div className={styles['c-card__pic']}>
            {product.imageURL && <img src={product.imageURL} />}
            <div className={styles['c-card__pic__labelframe']}>
              {customerLedgerEnabled && product?.shouldShowOnlyForLoggedInCustomer && (
                <div className={styles['c-card__pic__labelframe__recommend']}>
                  <div>
                    <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
                  </div>
                  <p>{t('Members Only')}</p>
                </div>
              )}
              {!!discountText && (
                <div className={styles['c-card__pic__labelframe__promotion']}>{discountText}</div>
              )}
            </div>
          </div>
          <div className={styles['c-card__header']}>
            {product.reviewCount != null && product.reviewCount != 0 && (
              <div className={styles['c-card__header__star']}>
                <img src="/static/images/ic_star_on.svg" alt="star" />
                <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
                <span>({product.reviewCount})</span>
              </div>
            )}
            <div
              className={styles['c-card__header__tags']}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {product.tags?.length > 0 && (
                <>
                  <div
                    className={clsx(
                      styles['c-card__header__tags__txt'],
                      baseStyles['color-txt--white'],
                      baseStyles['color-bg--black']
                    )}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <rect width="32" height="32" fill="none" />
                        <g>
                          <path
                            d="M14.17,2.27H4.27a2,2,0,0,0-2,2v9.9a2,2,0,0,0,.59,1.42L16.41,29.14a2,2,0,0,0,2.83,0l9.9-9.9a2,2,0,0,0,0-2.83L15.59,2.86A2,2,0,0,0,14.17,2.27Zm-3.41,8.49a3,3,0,1,1,0-4.25A3,3,0,0,1,10.76,10.76Z"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </div>
                    {t('View')}
                  </div>
                  <ul className={clsx(styles['c-card__header__tags__list'], 'maincolor-border')}>
                    {product.tags?.map((tag) => (
                      <li key={tag}>
                        <p>{tag}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
          <h3 className={clsx(styles['c-card__ttl'], 'maincolor-txt')}>{product.name}</h3>
          <p className={styles['c-card__description']}>{product.description}</p>
          <div className={styles['c-card__bottom']}>
            <p className={styles['c-card__bottom__week']}>
              {t('Days of week: {{operatingDaysText}}', {
                operatingDaysText: product.operatingWeekdays
                  .map((weekday) => getWeekdayText(weekday, t))
                  .join(','),
              })}
            </p>
            {config.enablePromotionPromotionSpecificationChanges ? (
              <div style={{ display: 'block' }}>
                {hasPromotion && (
                  <p
                    style={{ fontSize: '14px' }}
                    className={styles['c-card__bottom__original__price']}
                  >
                    {currencyFormatter.format(product.originalLowPrice)}
                  </p>
                )}
                <p className={clsx(styles['c-card__bottom__price'], 'maincolor-txt')}>
                  {getDisplayPrice(currencyFormatter.format(product.lowPrice), product)}
                </p>
              </div>
            ) : (
              <p className={clsx(styles['c-card__bottom__price'], 'maincolor-txt')}>
                {currencyFormatter.format(product.lowPrice)}
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  }

  return (
    <li className={styles['item__li']}>
      <Link href={productUrl} className={styles['item__li__pic']}>
        <img src={product.imageURL} />
        <div className={styles['c-card__pic__labelframe']}>
          {customerLedgerEnabled && product?.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}
          {!!discountText && (
            <div className={styles['c-card__pic__labelframe__promotion']}>{discountText}</div>
          )}
        </div>
      </Link>

      <div className={styles['item__li__info']}>
        <div className={clsx(styles['item__li__info__ttl'])}>
          <h2>{product.name}</h2>
        </div>

        {product.reviewCount != null && product.reviewCount != 0 && (
          <div className="c-card__header__star">
            <img src="/static/images/ic_star_on.svg" alt="star" />
            <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
            <span>({product.reviewCount})</span>
          </div>
        )}

        <div className={styles['item__li__info__tag']}>
          {product.tags?.map((tag) => (
            <p key={tag}>{tag}</p>
          ))}
        </div>

        <p className={styles['item__li__info__desc']}>
          <span>{product.description}</span>
        </p>

        <div className={styles['item__li__info__bottom']}>
          <div className={styles['item__li__info__bottom__left']}>
            <p className={styles['item__li__info__bottom__left__week']}>
              {t('Days of week: {{operatingDaysText}}', {
                operatingDaysText: product.operatingWeekdays
                  .map((weekday) => getWeekdayText(weekday, t))
                  .join(','),
              })}
            </p>
            <p className={clsx(styles['item__li__info__bottom__left__area'])}>{product.location}</p>
          </div>
          {config.enablePromotionPromotionSpecificationChanges ? (
            <div style={{ display: 'block' }}>
              {hasPromotion && (
                <p className={styles['c-card__bottom__original__price']}>
                  {currencyFormatter.format(product.originalLowPrice)}
                </p>
              )}
              <p className={clsx(styles['item__li__info__bottom__price'])}>
                {getDisplayPrice(currencyFormatter.format(product.lowPrice), product)}
              </p>
            </div>
          ) : (
            <p className={clsx(styles['item__li__info__bottom__price'])}>
              {currencyFormatter.format(product.lowPrice)}
            </p>
          )}
        </div>

        <Link href={productUrl} className={clsx(styles['item__li__info__btn'])}>
          <p>{t('VIEW MORE')}</p>
        </Link>
      </div>
    </li>
  );
};

export default ProductSummaryCardView;
